import { IHelpParams } from '@models/help.model';

const ApiRoutes = {
  auth: {
    login: '/user/login',
    register: '/user/register',
    checkUser: '/user/checkuser',
    logout: '/user/logout',
    otp: '/user/otp',
    otpWithExpired: (notifType: string) => {
      return '/user/otpwithexpired?NotifType=' + notifType;
    },
    otpVerify: '/user/otpverify',
    otpVerifyExternal: '/user/otpverifyexternal',
    resetPin: '/user/resetpin',
    changePin: '/user/changepin'
  },
  referral: {
    checkReferral: (referalCode: string) => {
      return `/referral/isvalidreferralcode?referralcode=${referalCode}`;
    },
    getHistoryMemberStatus: (cardNumber: string) => {
      return '/member/referralhistorymemberstatus?cardnumber=' + cardNumber;
    },
    getHistoryChildMember: '/member/referralhistorychildmember',
    getLearnMore: (params: string = 'referral-learn-more') => {
      return '/content/clientcontentwithbanner?q=' + params;
    },
    getShareLink: '/referral/sharelinks',
    getShortUrlDetail: (referralCode: string) => {
      return '/referral/getshorturldetail?shorturlcode=' + referralCode;
    },
    getBenefitInfo: '/referral/GetBenefitInfoMember'
  },
  address: {
    city: (city: string, district: string = '') => {
      return `/kalbe/getkecamatan?region=${city}&district=${district}`;
    },
    district: (district: string) => {
      return '/kalbe/getdistrict?request=' + district;
    },
    village: (village: string) => {
      return '/kalbe/getkelurahan?district=' + village;
    },
    list: '/shippingaddress/getalluseraddress',
    detail: (addressId: number) => {
      return '/shippingaddress/getuseraddressbyid?request=' + addressId;
    },
    setMain: (addressId: number) => {
      return '/shippingaddress/setdefaultaddress?request=' + addressId;
    },
    store: '/shippingaddress/insertusershippingaddress',
    update: '/shippingaddress/updateusershippingaddress',
    delete: (addressId: number) => {
      return '/shippingaddress/getuseraddressbyid?request=' + addressId;
    }
  },
  user: {
    updateProfile: '/user/updateprofile',
    getProfile: '/user/profile',
    getProfileWithShipping: '/user/profilewithshippingaddress',
    cookies: '/user/cookies'
  },
  contact: {
    store: '/contactus/process',
    categories: '/contactus/getcategories',
    topic: '/get-contact-us-topic'
  },
  card: {
    list: '/client/getlistclient',
    list_external: '/client/GetListClientExternal',
    add: '/member/addcard',
    addByCardNumber: '/usercard/addbycardnumber',
    show: (cardNumber: string) => {
      return `/usercard/showcard?request=${cardNumber}`;
    },
    hide: (cardNumber: string) => {
      return `/usercard/hidecard?request=${cardNumber}`;
    },
    loginStatusCard: (cardNumber: string) => {
      return `/usercard/LoginStatusCard?request=${cardNumber}`;
    }
  },
  brand: {
    list: '/productpoint/getbrands',
    products: '/productpoint/list',
    list_external: '/client/GetListClientExternal'
  },
  member: {
    profile: (cardNumber: string) => {
      return '/member/memberprofile?request=' + cardNumber;
    },
    pointHistory: '/member/poinhistories',
    topupHistory: '/member/transactiontopup',
    redemptionHistory: '/member/transactionredemption',
    infoMembershipType: '/client/infomembershiptype',
    uploadReceipt: '/member/uploadreceipt',
    getVouchers: '/member/vouchers',
    getDetailVoucher: '/member/voucherdetail',
    getMediaContact: (cardNumber: string) => {
      return '/member/getcontacts?request=' + cardNumber;
    },
    requestOtp: '/notification/sendotp',
    resetPassword: '/usercard/resetpasswordwithotp',
    loginMember: '/usercard/loginmembercard',
    getPointInfo: (cardNumber: string) => {
      return '/member/poinexisting?request=' + cardNumber;
    },
    couponHistories: '/member/couponhistories',
    detailCouponHistory: (transactionNo: any) => {
      return '/Member/GetCouponDetail?transactionNo=' + transactionNo;
    },
    validateReceipt: '/member/validatereceipt'
  },
  terms: {
    tos: (type: number) => {
      return '/terms-and-conditions-chakra?is_type_register=' + type;
    }
  },
  redeem: {
    transaction: '/redeem/pushredeemtransaction',
    checkElectricityInquire: '/sepulsa/electricityinquire'
  },
  help: {
    main: '/chakracontent/getdefaultcontent',
    mainBrand: '/clientcontent/getdefaultcontent',
    helpCategory: '/chakracontent/gethelpcategories',
    helpCategoryBrand: '/clientcontent/gethelpcategories',
    helpContent: (params?: IHelpParams) => {
      let queryParams;

      if (params) {
        queryParams = new URLSearchParams(Object.assign(params)).toString();
      }

      return '/chakracontent/gethelpcontents?' + queryParams;
    },
    helpContentBrand: (params?: IHelpParams) => {
      let queryParams;

      if (params) {
        queryParams = new URLSearchParams(Object.assign(params)).toString();
      }

      return '/clientcontent/gethelpcontents?' + queryParams;
    },
    faqCategory: '/chakracontent/getfaqcategories',
    faqCategoryBrand: '/clientcontent/getfaqcategories',
    faqContent: (params?: IHelpParams) => {
      let queryParams;

      if (params) {
        queryParams = new URLSearchParams(Object.assign(params)).toString();
      }

      return '/chakracontent/getfaqs?' + queryParams;
    },
    faqContentBrand: (params?: IHelpParams) => {
      let queryParams;

      if (params) {
        queryParams = new URLSearchParams(Object.assign(params)).toString();
      }

      return '/clientcontent/getfaqs?' + queryParams;
    },
    terms: '/chakracontent/getterms',
    termslist: '/chakracontent/gettermslist',
    termsBrand: '/clientcontent/getterms',
    privacyPolicy: '/chakracontent/getPrivacyPolicy',
    privacyPolicyBrand: '/clientcontent/getPrivacyPolicy',
    getClientFeature: '/clientcontent/getfeature',
    // getWelcomeRegisterSPG: '/chakracontent/getcontent?slug=welcome-register-referral-spg',
    getWelcomeRegisterSPG: '/clientcontent/getcontentbyslug?slug=welcome-register-referral-spg'
  },
  catalog: {
    // list: '/catalogue/cataloguegiftlist',
    list: '/catalogue/getcatalogitem',
    detail: (catalogGiftId: number) => {
      return '/catalogue/detail?catalogGiftID=' + catalogGiftId;
    },
    categories: '/catalogue/GetGiftCategories',
    explorer: '/explorer/catalogs',
    exploreDetail: (catalogGiftId: number, clientCode: string) => {
      return '/explorer/getcatalog?catalogGiftID=' + catalogGiftId + '&clientCode=' + clientCode;
    }
  },
  cart: {
    list: (cardNumber: string) => {
      return '/cart/getlist?cardNumber=' + cardNumber;
    },
    add: '/cart/add',
    update: '/cart/update',
    delete: '/cart/delete'
  },
  wishlist: {
    list: (cardNumber: string) => {
      return '/wishlist/getlist?cardNumber=' + cardNumber;
    },
    addOrRemove: '/wishlist/set'
  },
  merchant: {
    list: '/merchant/getmerchants',
    detail: (merchantId: number) => {
      return '/merchant/getdata?merchantID=' + merchantId;
    },
    categories: '/merchant/getmerchantcategories'
  },
  banner: {
    list: (params: string = 'main-banner-home-page') => {
      return '/banner/clientbanner?q=' + params;
    }
  },
  promo: {
    list: '/merchantpromo/list',
    detail: (slug: string) => {
      return '/merchantpromo/get?slug=' + slug;
    },
    explorer: '/explorer/promos',
    exploreDetail: (slug: string, clientCode: string) => {
      return '/explorer/getpromo?slug=' + slug + '&clientCode=' + clientCode;
    }
  },
  dmqi: {
    getToken: '/oauth/token',
    decryptParamFPE: '/Utility/DecryptParamFPEUrl'
  }
};

export default ApiRoutes;
