import axiosClient from '@lib/axiosClient';
import ApiRoutes from '@routes/api_routes';
import { getBasicTokenWithApiKeySecret, setTicks } from '@helpers/crypto.helper';
import storageHelper from '@helpers/storage.helper';
import { format } from 'date-fns';
import { IAddCard } from '@models/card.model';

const cardService = {
  getListExternal: async () => {
    try {
      const ticks = setTicks();
      const basicToken = getBasicTokenWithApiKeySecret(ticks);

      const url = ApiRoutes.card.list_external;
      const response = await axiosClient.get(url, {
        headers: {
          Authorization: 'Basic ' + basicToken,
          RequestTime: ticks
        }
      });

      return response.data;
    } catch (err) {
      throw err;
    }
  },

  getList: async () => {
    try {
      const url = ApiRoutes.card.list;
      const response = await axiosClient.get(url);

      return response.data;
    } catch (err) {
      throw err;
    }
  },

  addCard: async (card: IAddCard, clientApiKey: string) => {
    try {
      const url = ApiRoutes.card.add;
      const response = await axiosClient.post(url, card, {
        headers: {
          ClientApiKey: clientApiKey
        }
      });

      return response.data;
    } catch (err) {
      throw err;
    }
  },

  addCardByNumber: async (card: any) => {
    try {
      const url = ApiRoutes.card.addByCardNumber;
      const response = await axiosClient.post(url, card);

      return response.data;
    } catch (err) {
      throw err;
    }
  },

  showCard: async (cardNumber: string) => {
    try {
      const url = ApiRoutes.card.show(cardNumber);
      const response = await axiosClient.get(url);

      return response.data;
    } catch (err) {
      throw err;
    }
  },

  hideCard: async (cardNumber: string) => {
    try {
      const url = ApiRoutes.card.hide(cardNumber);
      const response = await axiosClient.get(url);

      return response.data;
    } catch (err) {
      throw err;
    }
  },

  loginStatusCard: async (cardNumber: string) => {
    try {
      const url = ApiRoutes.card.loginStatusCard(cardNumber);
      const response = await axiosClient.get(url);

      return response.data;
    } catch (err) {
      throw err;
    }
  }
};

export default cardService;
