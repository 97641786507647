import dynamic from 'next/dynamic';

const Button = dynamic(() => import('@components/elements/button'));

import { Carousel } from '@mantine/carousel';
import { useGetCardExternals } from '@hooks/queries/card';
import { useRouter } from 'next/router';
import { ICard } from '@models/card.model';
import { useCarouselStyles } from '@hooks/styles/carousel';
import Image from 'next/image';

const OurPartner = () => {
  const router = useRouter();
  const { classes: carouselClasses } = useCarouselStyles();
  const { data: cards } = useGetCardExternals();

  return (
    <section className="our-partner" id="our-partner">
      <div className="our-partner--header">
        <div className="our-partner--header-title">
          <div className="our-partner--header-source">
            <Image
              src="/images/icons/dot.png"
              alt="dot"
              width={90}
              height={90}
              objectFit="contain"
              objectPosition="center"
            />
          </div>
          <h2 className="our-partner--header-ttext">Our Partner</h2>
        </div>
        <div className="our-partner--header-desc">
          <p className="our-partner--header-tdesc">
            Chakra Rewards is a customer reward platform for all program and multi-brand
          </p>
        </div>
      </div>

      <div className="our-partner--content">
        <div className="our-partner--content-view">
          <Carousel
            mx="auto"
            slideSize="33.333333%"
            slideGap="md"
            align={'start'}
            classNames={carouselClasses}>
            {cards?.data?.map((item: ICard) => (
              <Carousel.Slide key={item.clientCode}>
                <div className="our-partner--content-list">
                  <a className="our-partner--content-item" data-unq="landingpage-button-brand">
                    <div className="our-partner--content-image">
                      <img src={item.logoImageUrl} alt={item.name} />
                    </div>
                  </a>
                </div>
              </Carousel.Slide>
            ))}
          </Carousel>
        </div>
      </div>

      <div className="our-partner--action">
        <Button
          type="button"
          onClick={() => router.push('/contact-us')}
          isOutlined
          data-unq="landingpage-button-contact">
          Become our Partner
        </Button>
      </div>
    </section>
  );
};

export default OurPartner;
