import { decryptAES, encryptAES } from './crypto.helper';

const storageHelper = {
  get: (name: string) => {
    const storage = localStorage.getItem(name);
    let dec;

    if (storage) {
      dec = decryptAES(storage);

      return JSON.parse(dec);
    }

    return null;
  },
  set: (name: string, value: any) => {
    const enc = encryptAES(JSON.stringify(value));
    return localStorage.setItem(name, enc);
  },
  remove: (name: string) => {
    return localStorage.removeItem(name);
  },
  removeAll: () => {
    return localStorage.clear();
  }
};

export default storageHelper;
