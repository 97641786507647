declare global {
  interface Window {
    Moengage: any;
  }
}

export const onSetPrefMoengage = (phone: string) => {
  window.Moengage.add_unique_user_id(phone);
  window.Moengage.update_unique_user_id(phone);
};

export const onDestroyMoengage = () => {
  window.Moengage.destroy_session();
};

export const onTrackEvent = (name: string, attributes: any) => {
  window.Moengage.track_event(name, attributes);
};

export const onSetUserAttribute = (user: any) => {
  var firstName = user.firstName ?? '';
  var lastName = user.lastName ?? '';
  var email = user.email ?? '';
  var gender = user.gender ?? '';
  var phone = user.phoneNo ?? '';
  var userName = firstName + ' ' + lastName;
  var birthday = user.birthDate;

  window.Moengage.add_first_name(firstName);
  window.Moengage.add_last_name(lastName);
  window.Moengage.add_email(email);
  window.Moengage.add_gender(gender);
  window.Moengage.add_mobile(phone);
  window.Moengage.add_user_name(userName);
  window.Moengage.add_birthday(new Date(birthday));
};
