import * as CryptoJS from 'crypto-js';

// const encode_utf8 = (s: any) => {
//   return unescape(encodeURIComponent(s));
// };

const getLocalDate = () => {
  var now = new Date();
  var h = 7;

  now.setTime(now.getTime() + h * 60 * 60 * 1000);
  return now;
};

// ---------------------------------------------------------------------
// Set token authorization
// ---------------------------------------------------------------------
export const setLocalDate = () => {
  return getLocalDate();
};

// Set Ticks Now
export const setTicksNow = () => {
  const date = new Date();

  return date.getTime() * 10000 + 621355968000000000;
};

// Set Ticks Time
export const setTicks = () => {
  const localDate = getLocalDate();

  return localDate.getTime() * 10000 + 621355968000000000;
};

// Set Basic Token
export const getBasicToken = (handphone: string, pin: string, ticks: any) => {
  // API-Sign = Message signature using HMAC-SHA512 of (URI path + SHA256(nonce + POST data)) and base64 decoded secret API key
  const message = handphone + ':' + pin + ':' + ticks;

  const hash = CryptoJS.SHA256(message);

  // var encodeHash = encode_utf8(handphone + ":" + hash);

  let wordArray = CryptoJS.enc.Utf8.parse(handphone + ':' + hash);
  const secret_buffer = wordArray.toString(CryptoJS.enc.Base64);

  return secret_buffer;
};

// Set Basic Token with ApiSecret
export const getBasicTokenWithApiSecret = (handphone: string, ticks: any) => {
  const apiSecret = process.env.NEXT_PUBLIC_API_SECRET;
  const message = handphone + ':' + apiSecret + ':' + ticks;
  const hash = CryptoJS.SHA256(message);

  let wordArray = CryptoJS.enc.Utf8.parse(handphone + ':' + hash);
  const secret_buffer = wordArray.toString(CryptoJS.enc.Base64);

  return secret_buffer;
};

// Set Basic Token
export const getBasicTokenWithApiKeySecret = (ticks: any) => {
  const apiKey = process.env.NEXT_PUBLIC_API_KEY;
  const apiSecret = process.env.NEXT_PUBLIC_API_SECRET;
  const message = apiKey + ':' + apiSecret + ':' + ticks;
  const hash = CryptoJS.SHA256(message);

  let wordArray = CryptoJS.enc.Utf8.parse(apiKey + ':' + hash);
  const secret_buffer = wordArray.toString(CryptoJS.enc.Base64);

  return secret_buffer;
};

// Set Member Token
export const getMemberToken = (cardNumber: string, password: string) => {
  var message = cardNumber + ':' + password;
  const hash = CryptoJS.SHA256(message);

  var wordArray = CryptoJS.enc.Utf8.parse(cardNumber + ':' + hash);
  var secret_buffer = wordArray.toString(CryptoJS.enc.Base64);
  return secret_buffer;
};

export const encryptAES = (value: string, key: string = 'chAkuR3@.') => {
  const ciphertext = CryptoJS.AES.encrypt(value, key);
  return encodeURIComponent(ciphertext.toString());
};

export const decryptAES = (value: string, key: string = 'chAkuR3@.') => {
  const decodedStr = decodeURIComponent(value);
  return CryptoJS.AES.decrypt(decodedStr, key).toString(CryptoJS.enc.Utf8);
};
