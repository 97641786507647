import { createStyles } from '@mantine/core';

export const useCarouselStyles = createStyles((_theme, _params, getRef) => ({
  controls: {
    ref: getRef('controls'),
    transition: 'opacity 0.4s ease',
    opacity: 0
  },

  root: {
    '&:hover': {
      [`& .${getRef('controls')}`]: {
        opacity: 1
      }
    }
  }
}));
