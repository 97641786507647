import storageHelper from '@helpers/storage.helper';
import axios from 'axios';
import * as Sentry from '@sentry/nextjs';
import { onDestroyMoengage } from '@utils/Moengage';

const transaction = Sentry.startTransaction({ name: `${process.env.NEXT_PUBLIC_SENTRY_RELEASE}` });
Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(transaction));

// ** Create instance Axios
const axiosClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  timeout: 120000,
  headers: {
    ApplicationChannel: `${process.env.NEXT_PUBLIC_APPLICATION_CHANNEL}`,
    'Content-Type': 'application/json'
  }
});

const requestHandler = (request: any) => {
  const userData = storageHelper.get(`${process.env.NEXT_PUBLIC_STORAGE_TOKEN_KEY_NAME_PROFILE}`);
  const userToken = storageHelper.get(`${process.env.NEXT_PUBLIC_STORAGE_TOKEN_KEY_NAME_CHAKRA}`);

  if (userData && userToken) {
    request.headers = Object.assign(request.headers, {
      UserToken: userToken,
      DateTimeTick: userData.tick
    });
  }

  return request;
};

const responseHandler = async (response: any) => {
  const span = transaction.startChild({
    data: response?.data,
    op: 'task',
    description: `processing ${process.env.NEXT_PUBLIC_SENTRY_RELEASE} API Chakra result`
  });

  span.isSuccess();
  span.setStatus(response?.status);

  span.finish();
  transaction.finish();

  if (response?.data?.status?.code == '401') {
    await fetch(`${location.protocol}//${location.host}/api/logout`).then(() => {
      onDestroyMoengage();
      storageHelper.removeAll();
      window.location.href = '/login';
    });
  }

  return response;
};

const errorHandler = async (error: any) => {
  const span = transaction.startChild({
    data: error?.response?.data,
    op: 'error-task',
    description: `ERROR ${process.env.NEXT_PUBLIC_SENTRY_RELEASE} API Chakra`
  });

  span.setStatus(error?.response?.status ? error?.response?.status : 500);

  await Sentry.captureException(error);

  span.finish();
  transaction.finish();

  if (error?.response?.status == '401') {
    await fetch(`${location.protocol}//${location.host}/api/logout`).then(() => {
      onDestroyMoengage();
      storageHelper.removeAll();
      window.location.href = '/login';
    });
  }

  return Promise.reject(error);
};

// ** Set Axios as global interceptor
// ** Request Intercept
axiosClient.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

// ** Response Intercept
axiosClient.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export default axiosClient;
