import { useMutation, useQuery } from '@tanstack/react-query';
import cardService from '@services/card.service';

export const useGetCardExternals = (options?: any) => {
  return useQuery(['getCardExternals'], () => cardService.getListExternal(), {
    ...options
  });
};

export const useGetClientCodeByCardEx = (struk?: string, options?: any) => {
  return useQuery(['getClientCodeByCardEx'], () => cardService.getListExternal(), {
    enabled: struk ? true : false,
    ...options
  });
};

export const useGetCards = (options?: any) => {
  return useQuery(['getCards'], () => cardService.getList(), {
    staleTime: 0,
    ...options
  });
};

export const useAddCard = (clientApiKey: string = '', options?: any) => {
  return useMutation((card: any) => cardService.addCard(card, clientApiKey), {
    ...options
  });
};

export const useAddCardByNumber = (options?: any) => {
  return useMutation((card: any) => cardService.addCardByNumber(card), {
    ...options
  });
};

export const useShowCard = (options?: any) => {
  return useMutation((cardNumber: any) => cardService.showCard(cardNumber), {
    ...options
  });
};

export const useHideCard = (options?: any) => {
  return useMutation((cardNumber: string) => cardService.hideCard(cardNumber), {
    ...options
  });
};

export const useLoginStatusCard = (options?: any) => {
  return useMutation((cardNumber: string) => cardService.loginStatusCard(cardNumber), {
    ...options
  });
};
